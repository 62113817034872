.whitespace-break-spaces {
  white-space: break-spaces;
}

code, .code {
  @apply text-pink-600 bg-gray-100 rounded-lg px-1 whitespace-break-spaces;
  &.only-text {
    @apply text-gray-700;
  }
}

.kbd {
  @apply rounded-2xl p-2 text-sm border-gray-400 bg-gray-300 bg-opacity-10 border-opacity-30 inline-flex items-center justify-center;
  border-width: 1px;
  border-bottom-width: 2px;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Vazirmatn, Courier New, monospace;
  font-feature-settings: normal;
  font-variation-settings: normal;
  &.kbd-sm {
    @apply rounded p-3 text-base;
    height: 1.2em;
    width: 1.2em;
  }
}
