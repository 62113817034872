
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
//.mat-form-field-suffix,
//.mat-form-field-prefix {
//  top: -0.25rem!important;
//}
.mat-mdc-form-field {
  @apply mb-2
}
.mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field-icon-prefix {
  padding: 0 6px 0 5px!important;
}

.mdc-notched-outline__notch {
  border-right: 0!important;
}

.search-bar {
  @apply transition;
  &.growing.mat-focused {
    @apply w-full sm:w-3/4;
    &.grow-full {
      @apply sm:w-full;
    }
  }
}

//.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
//  top: 18px;
//}
//.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mat-mdc-floating-label--float-above {
//  --mat-mdc-form-field-label-transform: translateY(-19px) !important;
//  transform: var(--mat-mdc-form-field-label-transform) !important;
//}

.mat-mdc-form-field-hint.options-hint {
  @apply w-full;
  &:before {
    content: none;
  }
}

//.mat-mdc-form-field-infix{
//  min-height: 36px!important;
//}
//.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
//  padding-top: 5px;
//  padding-bottom: 5px;
//}
