//.mat-dialog-title {
//  display: flex!important;
//}
.mdc-dialog__title {
  &::before {
    //height: 25px!important;
    display: unset!important;
  }
}


.side-modal .mat-mdc-dialog-content {
  max-height: unset!important;
}
