.container-section {
  @apply w-full p-2 sm:p-3 md:p-4 lg:p-6 xl:p-8;
  &.pb-0 {
    padding-bottom: 0;
  }
  &.pt-0 {
    padding-top: 0;
  }
  &.py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
}
