.pattern {
  background-color: rgba(249,250,251,0.3);
  background-image: linear-gradient(rgba(209,213,219,0.3) 1.3px, transparent 1.3px),
  linear-gradient(to right, rgba(209,213,219,0.3) 1.3px, rgba(249,250,251,0.3) 1.3px);
  background-size: 16px 16px;
}

.box {
  @apply bg-white shadow rounded-2xl p-3;
}
.box-info {
  @apply bg-primary-700 text-white text-xs px-2 py-0.5 rounded-2xl mb-2 absolute -top-6 left-4;
}

.spacer {
  @apply h-28 relative w-full;
  & .vertical-line {
    @apply absolute top-0 bottom-0 left-1/2 w-0.5 h-full bg-primary-700 -translate-x-1/2;
  }
  & button {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}
