@import "variables";

.basic-table {
  @apply table-auto w-full;
  & tr {
    @apply text-sm;
  }
  & thead tr {
    @apply text-gray-500 text-left;
    & th {
      @apply pb-3 font-medium;
      &:last-child {
        @apply text-right pr-2;
      }
    }
  }
  & tbody tr:nth-child(even) {
    @apply bg-gray-50;
  }
  & tbody tr td {
    @apply font-medium py-3;
    &:first-child {
      @apply rounded-l-lg px-2;
    }
    &:last-child {
      @apply rounded-r-lg;
    }
  }
}
