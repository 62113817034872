
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();
@include mat.typography-hierarchy(mat.m2-define-typography-config());

@import "assets/scss/variables";


$call-center-app-primary: mat.m2-define-palette(
  (
    50: rgba(8,14,28,0.05),
    100: rgba(8,14,28,0.1),
    200: rgba(8,14,28,0.2),
    300: rgba(8,14,28,0.3),
    400: rgba(8,14,28,0.4),
    500: rgba(8,14,28,0.5),
    600: rgba(8,14,28,0.6),
    700: rgba(8,14,28,0.7),
    800: rgba(8,14,28,0.8),
    900: rgba(8,14,28,1),
    A100: #d7dcdf,
    A200: #c3cbcf,
    A400: #9ba8af,
    A700: #5f737e,
    contrast: (
      50: $dark-text,
      100: $dark-text,
      200: $dark-text,
      300: $light-text,
      400: $light-text,
      500: $light-text,
      600: $light-text,
      700: $light-text,
      800: $light-text,
      900: $light-text,
      A100: $dark-text,
      A200: $light-text,
      A400: $light-text,
      A700: $light-text,
    )
  )
);
$call-center-app-secondary: mat.m2-define-palette(
    (
      50: rgba(236,125,128,0.05),
      100: rgba(236,125,128,0.1),
      200: rgba(236,125,128,0.2),
      300: rgba(236,125,128,0.3),
      400: rgba(236,125,128,0.4),
      500: rgba(236,125,128,0.5),
      600: rgba(236,125,128,0.6),
      700: rgba(236,125,128,0.7),
      800: rgba(236,125,128,0.8),
      900: rgba(236,125,128,1),
      A100: #fbe5e6,
      A200: #f9d8d9,
      A400: #f6bec0,
      A700: #f09799,
      contrast: (
        50: $dark-text,
        100: $dark-text,
        200: $dark-text,
        300: $light-text,
        400: $light-text,
        500: $light-text,
        600: $light-text,
        700: $light-text,
        800: $light-text,
        900: $light-text,
        A100: $dark-text,
        A200: $light-text,
        A400: $light-text,
        A700: $light-text,
      )
    )
);

$call-center-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$call-center-app-theme: mat.m2-define-light-theme((
  color: (
    primary: $call-center-app-primary,
    accent: $call-center-app-secondary,
    warn: $call-center-app-warn,
  ),
  density: -1, // default is 0
));

@include mat.all-component-themes($call-center-app-theme);

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "assets/scss/animations";
@import "assets/scss/buttons";
@import "assets/scss/global";
@import "assets/scss/tabs";
@import "assets/scss/tables";
@import "assets/scss/pills";
@import "assets/scss/dividers";
@import "assets/scss/text-helpers";
@import "assets/scss/dialog";
@import "assets/scss/forms";
@import "assets/scss/json";
@import "assets/scss/sortable";
@import "assets/scss/sections";
@import "assets/scss/progress-bar";
@import "assets/scss/interpolations";
@import "assets/scss/fonts";
@import "assets/scss/code-editor";
@import "assets/scss/accordeon";
@import "assets/scss/editors";
@import "assets/scss/dropdowns";
@import "assets/scss/workflow-views";

@import "jsoneditor/dist/jsoneditor.min.css";

@import "quill/dist/quill.snow.css";
@import "quill/dist/quill.bubble.css";
