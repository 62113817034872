@import "variables";

.pill-success { @apply rounded-2xl bg-green-50 text-green-700 p-2; }
.pill-danger { @apply bg-red-50 text-red-700 p-2 rounded-xl; }
.pill-warning { @apply bg-amber-50 text-amber-600 p-2 rounded-xl; }
.pill-primary { @apply rounded-2xl bg-primary-50 text-primary-800 p-2; }
.pill-secondary { @apply rounded-2xl bg-secondary-50 text-secondary-800 p-2; }
.pill-flat { @apply rounded-2xl text-gray-600 p-2; }

.pill-sm {
  @apply text-xs p-1 rounded-xl;
}

.pill-clearbit { background-color: rgb(24,155,235); @apply rounded-2xl text-white p-2; }
.pill-cognism { background-color: #4e3cbb; @apply rounded-2xl text-white p-2; }
.pill-adaptIo { background-color: #2648f7; @apply rounded-2xl text-white p-2; }
.pill-zoominfo { background-color: #F44242FF; @apply rounded-2xl text-white p-2; }
.pill-apollo { background-color: rgb(254,207,65); @apply rounded-2xl text-black p-2; }
.pill-lusha { background-color: rgb(95,12,254); @apply rounded-2xl text-white p-2; }
.pill-seamless { background-color: rgb(0,101,242); @apply rounded-2xl text-white p-2; }
.pill-exactbuyer { background-color: #306ca3; @apply rounded-2xl text-white p-2; }
.pill-funnelbeam { background-color: #dd2440; @apply rounded-2xl text-white p-2; }
.pill-rocketreach { background-color: #1eaaf1; @apply rounded-2xl text-white p-2; }
.pill-experian { background-color: #982881; @apply rounded-2xl text-white p-2; }
.pill-klazify { background-color: #5661b3; @apply rounded-2xl text-white p-2; }
.pill-peopledatalabs { background-color: rgb(92, 54, 186); @apply rounded-2xl text-white p-2; }
.pill-salesengine { background-color: #03a675; @apply rounded-2xl text-white p-2; }
.pill-thecompaniesapi { background-color: #4C51BF; @apply rounded-2xl text-white p-2; }
.pill-companda { background-color: #160647; @apply rounded-2xl text-white p-2; }
.pill-arounddeal { background-color: #408E84; @apply rounded-2xl text-white p-2; }

.pill-hubspot { background-color: #f57722; @apply rounded-2xl text-white p-2; }
.pill-salesforce { background-color: #1798c1; @apply rounded-2xl text-white p-2; }
.pill-instantly { background-color: #1179fc; @apply rounded-2xl text-white p-2; }
.pill-asana { background-color: #ea6d6f; @apply rounded-2xl text-white p-2; }
