.ghost {
  @apply bg-white rounded-xl opacity-50;
}

.sortable-container {
  border: 0!important;
  & .sortable-list .active {
    @apply rounded-xl opacity-50;
  }
}
