// Json editor
.jsoneditor {
  @apply rounded-xl border-gray-300;
}
.ace_editor {
  @apply rounded-t-xl;
}
.jsoneditor-statusbar {
  @apply rounded-b-xl;
}


// Quill editor
.ql-toolbar {
  @apply rounded-t-xl sticky -top-5 z-20 bg-gray-100
}
.ql-container {
  @apply rounded-b-xl
}
