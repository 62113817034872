$divider-margin-x: 1rem;

// Divider
.divider-new {
  &:before,
  &:after {
    content: '';
    height: 1.5px;
    flex: 1;
    background: #c6c6c6;
  }
  &:before {
    margin: 0 $divider-margin-x 0 0;
  }
  &:after {
    margin: 0 0 0 $divider-margin-x;
  }
}
