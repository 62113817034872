@import "variables";


.transition {
  transition: $transition!important;
}

.rotate {
  -webkit-animation: Rotate 1s linear infinite;
  -moz-animation: Rotate 1s linear infinite;
  animation: Rotate 1s linear infinite;
}
@-webkit-keyframes Rotate {
  0%{transform: rotate(0)}
  100%{transform: rotate(360deg)}
}
@-moz-keyframes Rotate {
  100% { -moz-transform: rotate(360deg); }
}
@keyframes Rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}

.blink {
  -webkit-animation: Blink 1s linear infinite;
  -moz-animation: Blink 1s linear infinite;
  animation: Blink 1s linear infinite;
}
@-webkit-keyframes Blink {
  0%{visibility: visible}
  50%{visibility: hidden}
}
@-moz-keyframes Blink {
  0%{visibility: visible}
  50%{visibility: hidden}
}
@keyframes Blink {
  0%{visibility: visible}
  50%{visibility: hidden}
}

//Loader
.loader {
  width: 8vmax;
  height: 8vmax;
  border-right: 4px solid $primary;
  border-radius: 100%;
  animation: spinRight 1s linear infinite;

  &:before {
    content: '';
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid $primary;
    border-radius: 100%;
    animation: spinLeft 1s linear infinite;
  }

  &:after {
    content: '';
    width: 4vmax;
    height: 4vmax;
    display: block;
    position: absolute;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-radius: 100%;
    border-right: 2px solid $primary;
    animation: spinLeft 0.75s linear infinite;
  }
}

@keyframes spinLeft {
  from {transform:rotate(0deg);}
  to {transform:rotate(720deg);}
}

@keyframes spinRight {
  from {transform:rotate(360deg);}
  to {transform:rotate(0deg);}
}

