@import "variables";

.btn {
  @apply px-4 py-2 rounded-xl transition cursor-pointer;
  &.btn-sm {
    @apply px-2 py-1 text-sm;
  }
  &.btn-xs {
    @apply px-0.5 py-0.5 text-xs;
    &.more-x-padding {
      @apply px-1;
    }
    &.big-x-padding {
      @apply px-2;
    }
  }

  &.btn-primary {
    @apply bg-primary-100 border-primary-400 text-primary-900 hover:bg-primary-900 hover:text-white;
  }

  &.btn-secondary {
    @apply bg-secondary-100 border-secondary-400 text-secondary-900 hover:bg-secondary-900 hover:text-white;
  }

  &.btn-tertiary {
    @apply bg-tertiary-100 border-tertiary-400 text-tertiary-900 hover:bg-tertiary-900 hover:text-white;
  }

  &.btn-accent {
    @apply bg-accent-100 border-accent-400 text-accent-900 hover:bg-accent-900 hover:text-white;
  }

  &.btn-neutral {
    @apply bg-gray-200 text-gray-600 hover:bg-accent-900 hover:text-white;
  }

  &.btn-flat {
    @apply text-gray-600 hover:bg-accent-200 hover:text-accent-900 px-2;
    &.secondary {
      @apply hover:bg-secondary-200 hover:text-secondary-900
    }
  }

  &.btn-danger {
    @apply bg-red-50 border-red-500 text-red-500 hover:bg-red-500 hover:text-white;
  }

  &.btn-processing {
    @apply cursor-not-allowed bg-amber-100 border-amber-500 text-amber-700;
    & i {
      @apply rotate;
    }
  }

  &.btn-white {
    @apply bg-white border border-primary-400 text-gray-700 hover:border-primary-900 hover:bg-primary-900 hover:text-white;
  }

}

$actionSize: 1.5rem;
.btn-action-table {
  width: $actionSize;
  height: $actionSize;
  line-height: $actionSize;
  @apply inline-block rounded-full transition text-center cursor-pointer;
  &.btn-primary {
    @apply bg-primary-50 text-primary-700 hover:bg-primary-900 hover:text-white;
  }
  &.btn-secondary {
    @apply bg-secondary-50 text-secondary-700 hover:bg-secondary-900 hover:text-white;
  }
  &.btn-danger {
    @apply bg-red-50 border-red-500 text-red-500 hover:bg-red-500 hover:text-white;
  }
}

.basic-link {
  @apply text-primary-900 transition hover:underline hover:text-accent-900 cursor-pointer;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-toggle-checked {
  @apply bg-primary-500 text-white font-bold transition
}

$animationTime: 0.2s;
.btn-flip{
  opacity: 1;
  outline: 0;
  position: relative;
  text-align: center;
  display: inline-block;
  text-decoration: none;

  &:hover{
    &:after{
      opacity: 1;
      transform: translateY(4px) rotateX(0);
    }
    &:before{
      opacity: 0;
      transform: translateY(75%) rotateX(90deg);
    }
  }

  &:after{
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    display: block;
    transition: $animationTime;
    position: absolute;
    content: attr(data-back);
    transform: translateY(-50%) rotateX(90deg);
  }

  &:before{
    top: 0;
    left: 0;
    opacity: 1;
    display: block;
    transition: $animationTime;
    position: relative;
    content: attr(data-front);
    transform: translateY(0) rotateX(0);
  }
}
