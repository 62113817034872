.interpolation {
  &::before {
    content: "\f02b"; // Tags icon
    font-family: "Font Awesome 6 Free"; // Adjust for your Font Awesome version
    font-weight: 900; // Adjust if you're using solid, regular, or light icons
    margin-right: 0.5rem; // Space between the icon and text
    display: inline-block; // Ensures proper alignment
  }
  @apply bg-white shadow rounded px-1.5 py-0.5 transition hover:text-white hover:bg-primary-900 cursor-pointer;
  & i {
    @apply transition hover:text-primary-900 cursor-pointer;
  }
  &.not-found {
    @apply bg-red-500 text-white hover:bg-primary-900;
  }
}
