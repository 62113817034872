
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();
@include mat.typography-hierarchy(mat.m2-define-typography-config());

@import "assets/scss/variables";


$call-center-app-primary: mat.m2-define-palette(
  (
    50: rgba(249,177,25,0.05),
    100: rgba(249,177,25,0.1),
    200: rgba(249,177,25,0.2),
    300: rgba(249,177,25,0.3),
    400: rgba(249,177,25,0.4),
    500: rgba(249,177,25,0.5),
    600: rgba(249,177,25,0.6),
    700: rgba(249,177,25,0.7),
    800: rgba(249,177,25,0.8),
    900: rgba(249,177,25,1),
    A100: #8c9eff,
    A200: #536dfe,
    A400: #3d5afe,
    A700: #304ffe,
    contrast: (
      50: $dark-text,
      100: $dark-text,
      200: $dark-text,
      300: $light-text,
      400: $light-text,
      500: $light-text,
      600: $light-text,
      700: $light-text,
      800: $light-text,
      900: $light-text,
      A100: $dark-text,
      A200: $light-text,
      A400: $light-text,
      A700: $light-text,
    )
  )
);
$call-center-app-secondary: mat.m2-define-palette(
    (
      50: rgba(82,182,139,0.05),
      100: rgba(82,182,139,0.1),
      200: rgba(82,182,139,0.2),
      300: rgba(82,182,139,0.3),
      400: rgba(82,182,139,0.4),
      500: rgba(82,182,139,0.5),
      600: rgba(82,182,139,0.6),
      700: rgba(82,182,139,0.7),
      800: rgba(82,182,139,0.8),
      900: rgba(82,182,139,1),
      A100: #8c9eff,
      A200: #536dfe,
      A400: #3d5afe,
      A700: #304ffe,
      contrast: (
        50: $dark-text,
        100: $dark-text,
        200: $dark-text,
        300: $light-text,
        400: $light-text,
        500: $light-text,
        600: $light-text,
        700: $light-text,
        800: $light-text,
        900: $light-text,
        A100: $dark-text,
        A200: $light-text,
        A400: $light-text,
        A700: $light-text,
      )
    )
);

$call-center-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$call-center-app-theme: mat.m2-define-light-theme((
  color: (
    primary: $call-center-app-primary,
    accent: $call-center-app-secondary,
    warn: $call-center-app-warn,
  ),
  density: -1, // default is 0
));

@include mat.all-component-themes($call-center-app-theme);

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "assets/scss/animations";
@import "assets/scss/buttons";
@import "assets/scss/global";
@import "assets/scss/tabs";
@import "assets/scss/tables";
@import "assets/scss/pills";
@import "assets/scss/dividers";
@import "assets/scss/text-helpers";
@import "assets/scss/dialog";
@import "assets/scss/forms";
@import "assets/scss/json";
@import "assets/scss/sortable";
@import "assets/scss/sections";
@import "assets/scss/progress-bar";
@import "assets/scss/interpolations";
@import "assets/scss/fonts";
@import "assets/scss/code-editor";
@import "assets/scss/accordeon";
@import "assets/scss/editors";
@import "assets/scss/dropdowns";
@import "assets/scss/workflow-views";

@import "jsoneditor/dist/jsoneditor.min.css";

@import "quill/dist/quill.snow.css";
@import "quill/dist/quill.bubble.css";
